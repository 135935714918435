import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Switch,
  Typography,
  Button,
  Descriptions,
  Empty,
  Tooltip,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import "../../../Styles/TwoTables.scss";
import { toConvertDate } from "../../../Utils/Utils";

import history from "../../../Utils/History";
import cookie from "react-cookies";
import {
  getIds,
  getUnpaiedClient,
  toCreateFacture,
  toGetUnPaiedBL,
} from "../APIs";

const BlToFacture = ({
  blsToFacture,
  visible,
  onAddBl,
  onRemoveBl,
  setCreateFactureModalVisible,
  AppStore,
}) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ disbledNo: true, disabledDate: true });
  const [blsTodisplay, setBlsTodisplay] = useState([]);
  const [ids, setIds] = useState([]);
  useEffect(() => {
    form.setFieldsValue({
      factNo: "",
      factNoAuto: true,
      date: "",
      dateAuto: true,
    });

    if (blsToFacture.length > 0) {
      getUnpaiedClient(blsToFacture[0].clientId).then((blsByClient) => {
        var res = [];
        blsByClient.forEach((x) => {
          const itemIndex = blsToFacture.findIndex((a) => a.id === x.id);
          if (itemIndex == -1) {
            res.push(x);
          }
        });
        setBlsTodisplay(res);
      });
    }
    getIds(new Date()).then((res) => {
      setIds(res);
    });
  }, [blsToFacture]);

  const onValuesChange = () => {
    setState({
      disbledNo: form.getFieldValue("factNoAuto"),
      disabledDate: form.getFieldValue("dateAuto"),
    });
  };

  const creeFacture = () => {
    AppStore.setFirst(1);
    AppStore.setSecond(9);
    toCreateFacture(blsToFacture, form.getFieldsValue()).then(() => {
      history.push("/vente/factures");
      cookie.save("selectedMenu", 9, { path: "/" });
    });
  };
  return (
    <Modal
      className="smtv-modal modal-large"
      title="Création de facture pour le client "
      visible={visible}
      footer={[
        <Button key="back" onClick={(e) => setCreateFactureModalVisible(false)}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={() => creeFacture()}>
          Créer la facture
        </Button>,
      ]}
    >
      <Form
        layout="vertical"
        labelAlign="center"
        form={form}
        onValuesChange={() => onValuesChange()}
      >
        <Row gutter="24" justify="center">
          <Col span="">
            <Form.Item name="factNo" label="Facture N°">
              <Select
                disabled={state.disbledNo}
                optionFilterProp="children"
                style={{ width: "200px" }}
              >
                <Select.Option value=""></Select.Option>
                {ids.map((id) => (
                  <Select.Option value={id}>{id}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item name="factNoAuto" label="Facture Automatique">
              <Switch checked={form.getFieldValue("factNoAuto")} />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item name="date" label="Date facture">
              <DatePicker
                disabled={state.disabledDate}
                onChange={() =>
                  getIds(form.getFieldValue("date")).then((res) => {
                    setIds(res);
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item name="dateAuto" label="Date Automatique">
              <Switch checked={form.getFieldValue("dateAuto")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="center">
        <Typography.Title level={5}>
          {blsToFacture.length > 0 && "Client: " + blsToFacture[0].clientName}
        </Typography.Title>
      </Row>
      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col
          lg={12}
          md={24}
          style={{ height: "100%", minHeight: "40vh", maxHeight: "40vh" }}
          className={"articles-list"}
        >
          <Row justify="center">
            <Typography.Link level={5}>
              Liste des bons de livraisons non facturée
            </Typography.Link>
          </Row>
          <div
            className="table-wrapper auto--overflow"
            style={{ marginTop: "2vh" }}
          >
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Référence</th>
                  <th className="ant-table-cell">Date</th>
                  <th className="ant-table-cell">Total</th>
                  <th className="ant-table-cell">Reste</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {blsTodisplay.map(
                  (bl) =>
                    bl.factureId == null && (
                      <tr>
                        <td className="ant-table-cell">{bl.ref}</td>
                        <td className="ant-table-cell">
                          {toConvertDate(bl.createdDate)}
                        </td>
                        <td className="ant-table-cell">
                          {bl.total.toFixed(3)}
                        </td>
                        <td className="ant-table-cell">
                          {bl.reste.toFixed(3)}
                        </td>
                        <td className="ant-table-cell">
                          <Tooltip title="Pour associer le BL">
                            <Button
                              type="link"
                              size="large"
                              onClick={() => onAddBl(bl)}
                              icon={<RightOutlined />}
                            ></Button>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
            <Row justify="center">
              {blsTodisplay.length === 0 && (
                <Empty
                  style={{ textAlign: "center" }}
                  description="pas de bon livraisons disponibles"
                />
              )}
            </Row>
          </div>
        </Col>
        <Col
          lg={12}
          md={24}
          style={{ height: "100%", minHeight: "40vh", maxHeight: "40vh" }}
          className="selection-list"
        >
          <Row justify="center">
            <Typography.Link level={5}>
              Liste des bons de livraisons dans la nouvelle facture
            </Typography.Link>
          </Row>
          <div
            className="table-wrapper auto--overflow"
            style={{ marginTop: "2vh" }}
          >
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell"></th>

                  <th className="ant-table-cell">Référence</th>
                  <th className="ant-table-cell">Date</th>
                  <th className="ant-table-cell">Total</th>
                  <th className="ant-table-cell">Reste</th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {blsToFacture.map((bl) => (
                  <tr>
                    <td className="ant-table-cell">
                      <Tooltip title="Pour dissocier le BL">
                        <Button
                          onClick={() => onRemoveBl(bl)}
                          type="link"
                          size="large"
                          icon={<LeftOutlined />}
                        ></Button>
                      </Tooltip>
                    </td>
                    <td className="ant-table-cell">{bl.ref}</td>
                    <td className="ant-table-cell">
                      {toConvertDate(bl.createdDate)}
                    </td>
                    <td className="ant-table-cell">{bl.total.toFixed(3)}</td>
                    <td className="ant-table-cell">{bl.reste.toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      {blsToFacture.length > 0 && (
        <Row>
          <Col span="18"></Col>
          <Col span="6">
            <Descriptions
              column={1}
              bordered
              justify="end"
              size="large"
              style={{ backgroundColor: "white", marginTop: "2vh" }}
            >
              <Descriptions.Item label="Total TTC" className="item-label">
                {blsToFacture.reduce((a, b) => a + b.total, 0).toFixed(3) +
                  " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total payé" className="item-label">
                {(
                  blsToFacture.reduce((a, b) => a + b.total, 0) -
                  blsToFacture.reduce((a, b) => a + b.reste, 0)
                ).toFixed(3) + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total Reste" className="item-label">
                {(
                  blsToFacture.reduce((a, b) => a + b.total, 0) -
                  (blsToFacture.reduce((a, b) => a + b.total, 0) -
                    blsToFacture.reduce((a, b) => a + b.reste, 0))
                ).toFixed(3) + " DT"}
              </Descriptions.Item>
            </Descriptions>
          </Col>{" "}
        </Row>
      )}
    </Modal>
  );
};

export default BlToFacture;
