import { useLocation } from "react-router-dom";
import { ITEMS_ACTIONS } from "../Actions/Actions";
import { errorMsg, toFormatArticle } from "../Utils/Utils";
import CAxios from "../Utils/CAxios";

const initialState = {
  items: [],
  total: 0,
  discount: 0,
  globalDiscountIsSet: false,
  client: null,
  achat: false,
  provider: null,
  emplacement: null,
  reglements: [],
  totalDiscount: 0,
  fodec: false,
};

const ItemsReducer = (state = initialState, { type, data }) => {
  let itemsUpdated = [],
    itemToUpdate,
    total = state.total,
    discount = state.discount,
    totalDiscount = 0;
  if (type === ITEMS_ACTIONS.SET_TOTAL) {
    itemsUpdated = [...state.items];
    for (let item of itemsUpdated) {
      item.discount = 0;
      item.total =
        getUnitPriceTTC(item, state.client, state.achat) * item.quantity;
    }
    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    totalDiscount = ((total - data.total) * 100) / total;
    totalDiscount = totalDiscount > 0 ? totalDiscount : 0;

    return {
      ...state,
      total: data.total,
      items: itemsUpdated,
      discount: totalDiscount,
      globalDiscountIsSet: true,
    };
  } else if (type === ITEMS_ACTIONS.SET_TOTAL_DISCOUNT) {
    itemsUpdated = [...state.items];
    if (data.totalDiscount == "") data.totalDiscount = 0;
    for (let index = 0; index < itemsUpdated.length; index++) {
      itemsUpdated[index].discount = data.totalDiscount;
      itemsUpdated[index].total =
        (parseFloat(itemsUpdated[index].uPriceTTC) *
          parseInt(itemsUpdated[index].quantity) *
          (100 - itemsUpdated[index].discount)) /
        100;
    }

    discount =
      calculateTotalDiscount(itemsUpdated) >= 0
        ? calculateTotalDiscount(itemsUpdated)
        : 0;

    return {
      ...state,
      items: itemsUpdated,
      discount: discount,
      totalDiscount: data.totalDiscount,
      globalDiscountIsSet: true,
      total: itemsUpdated.reduce((a, b) => a + b.total, 0),
    };
  } else if (type === ITEMS_ACTIONS.SET_SELECTED_ITEM) {
    const itemToUpdate = state.items.find((i) => data.ref === i.ref);
    return {
      ...state,
      selectedItem: itemToUpdate,
    };
  } else if (type === ITEMS_ACTIONS.SET_DETAILS) {
    const itemIndex = state.items.findIndex((a) => a.id === data.article.id);
    itemsUpdated = [...state.items];
    var newArticle = toFormatArticle(data.article);

    newArticle.uPriceHtInitial = getUnitPriceHT(
      newArticle,
      state.client,
      state.achat
    );
    newArticle.uPriceHt = getUnitPriceHT(newArticle, state.client, state.achat);
    newArticle.uPriceTTC = getUnitPriceTTC(
      newArticle,
      state.client,
      state.achat
    );
    newArticle.total = getUnitPriceTTC(newArticle, state.client, state.achat);

    newArticle.quantity = 1;
    newArticle.discount = 0;

    itemsUpdated[itemIndex] = newArticle;
    return {
      ...state,
      items: itemsUpdated,
      selectedItem: newArticle,
    };
  } else if (type === ITEMS_ACTIONS.SET_UNIT_PRICE_HT) {
    itemsUpdated = [...state.items];
    const itemToUpdate = state.items.find((i) => data.item.id === i.id);
    itemToUpdate.uPriceHt = data.value;

    itemToUpdate.uPriceTTC = (data.value * (100 + itemToUpdate.tva)) / 100;

    itemToUpdate.discount = 0;
    itemToUpdate.total =
      parseFloat(itemToUpdate.uPriceTTC) * parseInt(itemToUpdate.quantity);
    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    totalDiscount = itemsUpdated.reduce((a, b) => b.discount + a, 0);
    discount = 0;
    return {
      ...state,
      selectedItem: itemToUpdate,
      globalDiscountIsSet: false,
      items: itemsUpdated,
      discount,
      total,
    };
  } else if (type === ITEMS_ACTIONS.SET_UNIT_PRICE_TTC) {
    itemsUpdated = [...state.items];
    itemToUpdate = itemsUpdated.find((i) => data.item.id === i.id);
    itemToUpdate.uPriceTTC = data.value;
    itemToUpdate.uPriceHt = (data.value / (100 + itemToUpdate.tva)) * 100;

    itemToUpdate.discount = 0;
    itemToUpdate.total =
      parseFloat(itemToUpdate.uPriceTTC) * parseInt(itemToUpdate.quantity);
    totalDiscount = itemsUpdated.reduce((a, b) => b.discount + a, 0);
    discount = calculateTotalDiscount(itemsUpdated);

    return {
      ...state,
      selectedItem: itemToUpdate,
      globalDiscountIsSet: false,
      items: itemsUpdated,
      discount,
      total: itemsUpdated.reduce((a, b) => a + b.total, 0),
    };
  } else if (type === ITEMS_ACTIONS.ADD_ITEM) {
    if (!inventaire()) {
      if (state.client == null && !state.achat) {
        errorMsg("D'abord, vous devez séléctionner le client");
        return {
          ...state,
        };
      } else if (state.provider == null && state.achat) {
        errorMsg("D'abord, vous devez séléctionner le fournisseur");
        return {
          ...state,
        };
      }
    }

    const itemIndex = state.items.findIndex((a) => a.ref === data.item.ref);
    let selectedItem = { ...data.item };
    if (itemIndex !== -1) {
      itemsUpdated = [...state.items];
      itemsUpdated[itemIndex].quantity++;
      itemsUpdated[itemIndex].total =
        (itemsUpdated[itemIndex].uPriceTTC *
          itemsUpdated[itemIndex].quantity *
          (100 - itemsUpdated[itemIndex].discount)) /
        100;
    } else {
      selectedItem = { ...data.item };
      if (!state.achat) {
        getLastPaiedValue(selectedItem.id, state.client.id).then((res) => {
          selectedItem.uLastPaied = parseFloat(res).toFixed(3);
        });
      }
      selectedItem.uPriceHtInitial = getUnitPriceHT(
        selectedItem,
        state.client,
        state.achat
      );
      selectedItem.uPriceHt = getUnitPriceHT(
        selectedItem,
        state.client,
        state.achat
      );
      selectedItem.uPriceTTC = getUnitPriceTTC(
        selectedItem,
        state.client,
        state.achat
      );
      selectedItem.total = getUnitPriceTTC(
        selectedItem,
        state.client,
        state.achat
      );

      selectedItem.quantity = 1;
      selectedItem.discount = 0;
      selectedItem.s__articleTitle = selectedItem.titre;
      itemsUpdated = state.items.concat(selectedItem);
    }
    total = state.total;

    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    discount = calculateTotalDiscount(itemsUpdated);

    return {
      ...state,
      selectedItem: selectedItem,
      total,
      discount,
      totalDiscount,
      items: itemsUpdated,
    };
  } else if (type === ITEMS_ACTIONS.ADD_ITEM_INTERNATIONAL) {
    let selectedItem = { ...data.item };
    selectedItem.uPriceHtInitial = getUnitPriceHT(
      selectedItem,
      state.client,
      state.achat
    );
    selectedItem.uPriceHt = getUnitPriceHT(
      selectedItem,
      state.client,
      state.achat
    );
    selectedItem.uPriceTTC =
      ((100 + selectedItem.tva) / 100) *
      getUnitPriceHT(selectedItem, state.client, state.achat);
    selectedItem.total =
      ((selectedItem.quantity * (100 + selectedItem.tva)) / 100) *
      getUnitPriceHT(selectedItem, state.client, state.achat);

    selectedItem.discount = 0;
    itemsUpdated = state.items.concat(selectedItem);
    return {
      ...state,
      selectedItem: selectedItem,
      total,
      discount,
      totalDiscount,
      items: itemsUpdated,
    };
  } else if (type === ITEMS_ACTIONS.REMOVE_ITEM) {
    itemsUpdated = state.items.filter((i) => i.id !== data.item.id);
    if (itemsUpdated.length === 0) return initialState;

    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    totalDiscount = itemsUpdated.reduce((a, b) => b.discount + a, 0);

    discount = calculateTotalDiscount(itemsUpdated);

    return {
      ...state,
      selectedItem: itemsUpdated.length > 0 ? itemsUpdated[0] : null,
      total,
      discount,
      items: itemsUpdated,
    };
  } else if (type === ITEMS_ACTIONS.SET_ITEM_QUANTITY) {
    itemsUpdated = [...state.items];
    itemToUpdate = itemsUpdated.find((i) => data.item.id === i.id);
    itemToUpdate.quantity = parseInt(data.quantity);
    itemToUpdate.total =
      itemToUpdate.uPriceTTC *
      parseInt(data.quantity) *
      ((100 - itemToUpdate.discount) / 100);

    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    totalDiscount = itemsUpdated.reduce((a, b) => b.discount + a, 0);
    discount = calculateTotalDiscount(itemsUpdated);

    return {
      ...state,
      selectedItem: itemToUpdate,
      items: itemsUpdated,
      total,
      discount,
    };
  } else if (type === ITEMS_ACTIONS.SET_ITEM_DISCOUNT) {
    if (isNaN(data.discount)) data.discount = 0;
    itemsUpdated = [...state.items];
    itemToUpdate = itemsUpdated.find((i) => data.item.id === i.id);
    if (itemToUpdate.discount != data.discount) {
      itemToUpdate.discount = data.discount;
    }

    itemToUpdate.total =
      (parseFloat(itemToUpdate.uPriceTTC) *
        parseInt(itemToUpdate.quantity) *
        (100 - itemToUpdate.discount)) /
      100;
    discount = calculateTotalDiscount(itemsUpdated);

    return {
      ...state,
      selectedItem: itemToUpdate,
      discount,
      total: itemsUpdated.reduce((a, b) => a + b.total, 0),
      globalDiscountIsSet: false,
      items: itemsUpdated,
      totalDiscount: 0,
    };
  } else if (type === ITEMS_ACTIONS.SET_ITEM_MARGE) {
    itemsUpdated = [...state.items];
    itemToUpdate = itemsUpdated.find((i) => data.item.id === i.id);
    if (itemToUpdate.marge != data.marge) {
      itemToUpdate.marge = data.marge;
    }

    return {
      ...state,
      selectedItem: itemToUpdate,
      discount,
      total: itemsUpdated.reduce((a, b) => a + b.total, 0),
      globalDiscountIsSet: false,
      items: itemsUpdated,
    };
  } else if (type === ITEMS_ACTIONS.SET_ITEM_DISCOUNT) {
    itemsUpdated = [...state.items];
    itemToUpdate = itemsUpdated.find((i) => data.item.id === i.id);

    itemToUpdate.marge = data.marge;

    return {
      ...state,
      selectedItem: itemToUpdate,
      discount,
      total: itemsUpdated.reduce((a, b) => a + b.total, 0),
      globalDiscountIsSet: false,
      items: itemsUpdated,
    };
  } else if (type === ITEMS_ACTIONS.SET_CLIENT) {
    itemsUpdated = [...state.items];

    total = itemsUpdated.reduce((a, b) => a + b.total, 0);

    return {
      ...state,
      items: itemsUpdated,
      client: data.client,
      total,
    };
  } else if (type === ITEMS_ACTIONS.SET_TITLE) {
    itemsUpdated = [...state.items];
    itemToUpdate = itemsUpdated.find((i) => data.item.id === i.id);

    itemToUpdate.titre = data.value;

    return {
      ...state,
      selectedItem: itemToUpdate,
      items: itemsUpdated,
    };
  } else if (type === ITEMS_ACTIONS.SET_PROVIDER) {
    itemsUpdated = [...state.items];

    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    return {
      ...state,
      items: itemsUpdated,
      provider: data.provider,
      total,
    };
  } else if (type === ITEMS_ACTIONS.SET_EMPLACEMENT) {
    itemsUpdated = [...state.items];

    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    return {
      ...state,
      items: itemsUpdated,
      emplacement: data.emplacement,
      total,
    };
  } else if (type === ITEMS_ACTIONS.SET_REGLEMENTS) {
    itemsUpdated = [...state.items];

    return {
      ...state,
      items: itemsUpdated,
      reglements: data.reglements,
    };
  } else if (type === ITEMS_ACTIONS.SET_FODEC) {
    itemsUpdated = [...state.items];
    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    if (data.fodec) {
      total += (itemsUpdated.reduce((a, b) => a + b.uPriceHt, 0) * 1) / 100;
    }
    return {
      ...state,
      items: itemsUpdated,
      fodec: data.fodec,
      total,
    };
  } else if (type === ITEMS_ACTIONS.SET_ACHAT) {
    itemsUpdated = [...state.items];
    total = itemsUpdated.reduce((a, b) => a + b.total, 0);
    state.achat = data.achat;
    return {
      ...state,
      items: itemsUpdated,
      total,
    };
  } else if (type === ITEMS_ACTIONS.CLEAR_ITEMS) {
    return { ...initialState, client: state.client };
  } else if (type === ITEMS_ACTIONS.SET_ITEMS_STATE) {
    return {
      ...state,
      ...data,
      selectedItem: data.items[0],
    };
  } else return state;
};

export default ItemsReducer;

const inventaire = () => {
  var location = window.location.href;
  if (
    location.includes("/achat/bs") ||
    location.includes("/achat/be") ||
    location.includes("/achat/inventory")
  ) {
    return true;
  }
  return false;
};
const getUnitPriceTTC = (item, client = null, achat) => {
  if (achat) {
    return item.prix_achat;
  }
  if (client && client.b__wholesaler) {
    return item.prix_unitaire_grosTTC;
  } else {
    return item.prix_unitaireTTC;
  }
};

const getUnitPriceHT = (item, client = null, achat) => {
  if (achat) {
    return item.prix_achatHT;
  }
  if (client && client.b__wholesaler) {
    return item.prix_unitaire_gros;
  } else {
    return item.prix_unitaire;
  }
};
const getLastPaiedValue = async (article, client) => {
  const { data } = await CAxios.post(`/api/bl/last`, {
    article,
    client,
  });
  return data.success.value;
};

const calculateTotalDiscount = (items) => {
  const totalBeforeDiscount = items.reduce(
    (a, b) => b.uPriceTTC * b.quantity + a,
    0
  );
  const totalAfterDiscount = items.reduce(
    (a, b) => (b.uPriceTTC * b.quantity * (100 - b.discount)) / 100 + a,
    0
  );

  return (totalBeforeDiscount - totalAfterDiscount).toFixed(2);
};
